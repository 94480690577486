import { AreaInterest, EntidadeService } from '@/domain/models'
import { atom } from 'recoil'

export const isLoadingProspectConfigsState = atom({
  key: 'isLoadingProspectConfigsState',
  default: false
})

export const prospectConfigsState = atom({
  key: 'prospectConfigsState',
  default: {
    ativo: false,
    descricao: '',
    moduloLiberado: false,
    listaInteresse: [] as EntidadeService[]
  }
})

export const prospectInterestAreaState = atom({
  key: 'prospectInterestAreaState',
  default: [] as AreaInterest[]
})
