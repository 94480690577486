import { Encoder } from '@/data/protocols'

export class EnvironmentEncoderAdapter implements Encoder {
  encode (value: string): string {
    const asciiValue = Buffer.from(value, 'ascii')
    const base64Value = asciiValue.toString('base64')

    return base64Value
  }
}
