import React, { useCallback } from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { GetServiceOrderDetail } from '@/domain/usecases'
import { Loading, Box, NoResultMessage, VStack } from '@/presentation/components'
import { useErrorHandler, useMediaQueries } from '@/presentation/hooks'
import { serviceOrderDetailState, PageHeader, ClientInfo, OsProducts, OsTotalizer, OsTreatments, OsSignatures, isLoadingServiceOrderDetailState, OsInfo, OsImages, PrintButton, isPrintingServiceOrderDetailState, OsImageView, OsChecklist } from '@/presentation/pages/ServiceOrderDetail/components'

type ServiceOrderDetailProps = {
  getServiceOrderDetail: GetServiceOrderDetail
}

export type ServiceOrderDetailUrlModel = {
  param: string
}

export const ServiceOrderDetail: React.FC<ServiceOrderDetailProps> = (props) => {
  const handleError = useErrorHandler()
  const { isMobile } = useMediaQueries()
  const isPrinting = useRecoilValue(isPrintingServiceOrderDetailState)
  const [isLoading, setIsLoading] = useRecoilState(isLoadingServiceOrderDetailState)
  const [serviceOrderDetail, setServiceOrderDetail] = useRecoilState(serviceOrderDetailState)

  const { param } = useRoute().params as ServiceOrderDetailUrlModel

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true)

      const tokenParam = {
        token: param
      }

      props.getServiceOrderDetail
        .getDetail(tokenParam)
        .then(setServiceOrderDetail)
        .catch(handleError)
        .finally(() => setIsLoading(false))
    }, [])
  )

  return (
    <Box style={{ ...styles.content, padding: isPrinting ? 0 : 16 }}>
      {isLoading
        ? <Loading text='Buscando OS' />
        : serviceOrderDetail?.codInterno
          ? <VStack style={{ paddingHorizontal: isPrinting || isMobile ? 8 : '24%' }}>
            <PageHeader />
            <PrintButton />
            <ClientInfo />
            <OsInfo />
            <OsTreatments />
            <OsProducts />
            <OsTotalizer />
            <OsImages />
            <OsChecklist />
            <OsSignatures/>
          </VStack>
          : <NoResultMessage text='A OS solicitada não foi encontrada' />
      }
      <OsImageView />
    </Box>
  )
}

const styles = StyleSheet.create({
  content: {
    marginVertical: 0,
    marginHorizontal: 'auto',
    flex: 1,
    width: '100%',
    backgroundColor: 'rgb(242, 242, 242)'
  }
})
