import { useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { AuthenticationModel, ClientAddress, ClientModel } from '@/domain/models'
import { makePortalLogout } from '@/main/factories/usecases'
import { authenticationState, localStorageState, jwtAdapterAtom, dateAdapterAtom } from '@/presentation/components'
import { useNotify } from '@/presentation/hooks'
import { version } from '../../../package.json'

export const KEY_ACCESS_TOKEN = 'access_token'
export const KEY_ACCESS_PASSWORD = '@expires_p'
export const KEY_SAVE_USER = 'save_user'
const EMMITERS_AUTHORIZED = [
  'http://integraservice.insidesistemas.com.br',
  'https://login.insidesistemas.com.br/api/v1/'
]
const ROLES_AUTHORIZED = [
  'Portal',
  'Service'
]

type SavedUser = {
  username: string
  saveUsername: boolean
}

type AuthProps = {
  getCurrentClient: () => string
  getClient: () => ClientModel
  getSaveUser: () => SavedUser
  saveUser: (user: SavedUser) => void
  setClient: (client: ClientModel) => void
  login: (token: AuthenticationModel) => void
  logout: () => void
  isSignedIn: () => boolean
  isAdmin: () => boolean
  isPasswordSecure: (password: string) => boolean
  setSecurePassword: () => void
  getAddress: (adressCode: number) => ClientAddress | undefined
}

export const useAuth = (): AuthProps => {
  const notify = useNotify()
  const [autentication, setAuthentication] = useRecoilState(authenticationState)
  const localStorageAdapter = useRecoilValue(localStorageState)
  const jwtAdapter = useRecoilValue(jwtAdapterAtom)
  const dateAdapter = useRecoilValue(dateAdapterAtom)

  const isAdmin = useCallback((): boolean => {
    const accessToken = localStorageAdapter.get(KEY_ACCESS_TOKEN)
    const tokenDecode = jwtAdapter.decode(accessToken?.access_token)
    const currentClient = tokenDecode?.sub || ''
    const loginWithCode = /^[0-9]+$/.test(currentClient?.toString())

    const isAdmin =
      currentClient?.toString().toUpperCase() === 'ADMIN' ||
      currentClient?.toString().endsWith('@insidesistemas.com.br') ||
      !loginWithCode

    return isAdmin
  }, [autentication?.accessToken])

  const login = useCallback((accessToken: AuthenticationModel): void => {
    localStorageAdapter.set(KEY_ACCESS_TOKEN, accessToken)
    setAuthentication(currentState => ({
      ...currentState,
      accessToken,
      registerLogin: true
    }))
  }, [])

  const logout = useCallback((): void => {
    localStorageAdapter.set(KEY_ACCESS_TOKEN, undefined)
    setAuthentication({
      client: null as unknown as ClientModel,
      accessToken: null as unknown as AuthenticationModel,
      registerLogin: false
    })
  }, [])

  const getCurrentClient = useCallback((): string => {
    const accessToken = localStorageAdapter.get(KEY_ACCESS_TOKEN)
    const tokenDecode = jwtAdapter.decode(accessToken?.access_token)    
    return tokenDecode?.sub as string
  }, [autentication?.accessToken])

  const getClient = useCallback((): ClientModel => {
    const accessToken = localStorageAdapter.get(KEY_ACCESS_TOKEN)

    if (accessToken?.access_token) {
      const accessTokenDecode = jwtAdapter.decode(accessToken.access_token)
      const codeClient = accessTokenDecode.sub as string

      if (codeClient.toUpperCase() === 'ADMIN') {
        return {
          ...null as unknown as ClientModel,
          codCliente: 0,
          nomeCliente: codeClient.toUpperCase()
        }
      } else if (codeClient?.endsWith('@insidesistemas.com.br')) {
        return {
          ...null as unknown as ClientModel,
          codCliente: 0,
          nomeCliente: accessTokenDecode?.nome,
          email: accessTokenDecode?.sub
        }
      } else if (isNaN(parseInt(codeClient))) {
        return {
          ...null as unknown as ClientModel,
          codCliente: 0,
          nomeCliente: codeClient
        }
      } else {
        return autentication.client
      }
    }

    return autentication.client
  }, [autentication.client])

  const isSignedIn = useCallback((): boolean => {
    const today = dateAdapter.today()
    const expireDate = new Date(0)
    const currentClient = getCurrentClient()
    const client = getClient()
    const isAdmin = currentClient?.toString().toUpperCase() === 'ADMIN' || currentClient?.toString().endsWith('@insidesistemas.com.br')
  
    if (!autentication?.accessToken?.access_token) {
      return false
    }

    const auth = jwtAdapter.decode(autentication?.accessToken?.access_token)
    if (!isAdmin) {
      if (!auth || !EMMITERS_AUTHORIZED.includes(auth.iss)) {
        notify.error('Token inválido, faça o login novamente!')
        logout()
        return false
      }

      const roles = auth['integraservice-webapi'] || auth['portal-service']
      if (typeof roles === 'string') {
        if (!ROLES_AUTHORIZED.includes(roles)) {
          notify.error('Token inválido, faça o login novamente!')
          logout()
          return false
        }
      } else {
        if ((roles as []).every(role => !ROLES_AUTHORIZED.includes(role))) {
          notify.error('Token inválido, faça o login novamente!')
          logout()
          return false
        }
      }
    }

    expireDate.setUTCSeconds(auth.exp)
    const isExpired = today > expireDate
    const hadAccessToken = !!localStorageAdapter.get(KEY_ACCESS_TOKEN)

    if (isExpired && hadAccessToken) {
      notify.error('Sessão Expirada, faça o login novamente!')
      logout()
      if (getCurrentClient()) {
        void makePortalLogout()
          .logout({ cliente: parseInt(getCurrentClient()), version })
          .catch(console.error)
      }

      return false
    }

    window?.clarity?.("identify", client?.codCliente?.toString(), client?.unidade?.toString(), window?.location?.host, client?.email)

    return true
  }, [autentication?.accessToken])

  const isPasswordSecure = useCallback((password: string): boolean => {
    const minLength = 8
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password)

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    )
  }, [])

  const setSecurePassword = useCallback((): void => {
    localStorageAdapter.set(KEY_ACCESS_PASSWORD, 'insecure_password')
  }, [])

  const setClient = useCallback((client: ClientModel): void => {
    setAuthentication(currentState => ({ ...currentState, client }))
  }, [])

  const getAddress = (adressCode: number): ClientAddress | undefined => {
    const client = getClient()
    const adresss = client.enderecoAlternativo?.find(address => address.codInterno === adressCode)

    return adresss
  }

  const getSaveUser = (): SavedUser => {
    let savedUser = localStorageAdapter.get(KEY_SAVE_USER) as SavedUser

    if (!savedUser) {
      savedUser = {
        username: '',
        saveUsername: false
      }
    }

    return savedUser
  }

  const saveUser = (user: SavedUser): void => {
    localStorageAdapter.set(KEY_SAVE_USER, user)
  }

  return {
    login,
    logout,
    isSignedIn,
    setClient,
    getCurrentClient,
    getClient,
    getAddress,
    isPasswordSecure,
    setSecurePassword,
    getSaveUser,
    saveUser,
    isAdmin
  }
}
