import React from 'react'
import { makeProspectRegistration } from '@/main/factories/usecases'
import { Prospect } from '@/presentation/pages/Prospect'
import { NavigationPagesProps } from '@/presentation/components'

export const makeProspectFactory = (props: NavigationPagesProps): JSX.Element => {
  return (
    <Prospect
      {...props}
      prospectRegistration={makeProspectRegistration()}
    />
  )
}
