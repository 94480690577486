import { AreaInterest, EntidadeService } from '@/domain/models'
import { ProspectRegistrationParams } from '@/domain/usecases'
import { atom } from 'recoil'

export const isOpenInterestAreaState = atom({
  key: 'isOpenInterestAreaState',
  default: false
})

export const selectedInterestAreaState = atom<AreaInterest>({
  key: 'selectedInterestAreaState',
  default: {
    descricao: '',
    email: '',
    interesse: null as unknown as EntidadeService,
    unidade: 0
  }
})

export const isLoadingProspectRegistrationState = atom({
  key: 'isLoadingProspectRegistrationState',
  default: false
})

export const isSendRegistrationState = atom({
  key: 'isSendRegistrationState',
  default: false
})

export const prospectRegistrationState = atom<ProspectRegistrationParams>({
  key: 'prospectRegistrationState',
  default: {
    codDEInteresse: 0,
    codUnidade: 0,
    nome: '',
    cpf: '',
    emailUrl: '',
    email: '',
    fax: '',
    fone: '',
    numeroEndereco: '',
    endereco: '',
    bairro: '',
    cep: '',
    cidade: '',
    complemento: '',
    estado: ''
  }
})
