import { useCallback } from 'react'
import { ProspectConfigsModel } from '@/domain/models'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { initialProspectConfigsState } from '@/presentation/pages/ProspectSettings/components'
import * as GeneralState from '@/presentation/components/Atoms'

type ProspectSettingsProps = {
  startConfig: (config: ProspectConfigsModel) => void
  discardChanges: () => void
  hasChanged: () => boolean
}

export const useProspectSettings = (): ProspectSettingsProps => {
  const [initialConfigs, setInitialConfigs] = useRecoilState(initialProspectConfigsState)
  const [prospectConfigs, setProspectConfigs] = useRecoilState(GeneralState.prospectConfigsState)
  const areaInterest = useRecoilValue(GeneralState.prospectInterestAreaState)
  const setProspectInterestArea = useSetRecoilState(GeneralState.prospectInterestAreaState)

  const discardChanges = useCallback((): void => {
    startConfig(initialConfigs)
  }, [initialConfigs])

  const startConfig = useCallback((intialProspectConfigs: ProspectConfigsModel): void => {
    const { areaInteresse, ...prospectConfigs } = intialProspectConfigs

    prospectConfigs.ativo = areaInteresse.length > 0
    setInitialConfigs({ ...prospectConfigs, areaInteresse })
    setProspectConfigs(prospectConfigs)
    setProspectInterestArea(areaInteresse)
  }, [])

  const hasChanged = useCallback((): boolean => {
    const configs: ProspectConfigsModel = {
      ...prospectConfigs,
      areaInteresse: areaInterest
    }
    return JSON.stringify(initialConfigs) !== JSON.stringify(configs)
  }, [prospectConfigs, areaInterest])

  return {
    startConfig,
    discardChanges,
    hasChanged
  }
}
