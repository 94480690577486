import React from 'react'
import { useRecoilValue } from 'recoil'
import { Box, CardDetails, CardRow, DataTable, dateAdapterAtom, Text, VStack } from '@/presentation/components'
import { serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { styles } from '../../styles'

export const OsTreatments: React.FC = () => {
  const serviceOrderDetail = useRecoilValue(serviceOrderDetailState)
  const dateAdapter = useRecoilValue(dateAdapterAtom)

  const handlePastTime = (initialHour: string, finalHour: string): string => {
    const initialTime = new Date(`1970-01-01T${initialHour}`)
    const finalTime = new Date(`1970-01-01T${finalHour}`)
    const durationInMilliseconds = finalTime.getTime() - initialTime.getTime()

    const hours = Math.floor(durationInMilliseconds / 3600000)
    const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000)
    const seconds = Math.floor((durationInMilliseconds % 60000) / 1000)

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const handleTotalDuration = (): string => {
    let totalHours = 0
    let totalMinutes = 0
    let totalSeconds = 0

    serviceOrderDetail.listaAtendimentos
      .filter(atendimento => atendimento.tipoAtendimento !== 'D')
      .forEach((atendimento) => {
        const [hours, minutes, seconds] = handlePastTime(
          dateAdapter.format(atendimento.hrInicio, 'HH:mm:ss'),
          dateAdapter.format(atendimento.hrFim, 'HH:mm:ss')
        ).split(':').map(Number)
        totalHours += hours
        totalMinutes += minutes
        totalSeconds += seconds
      })

    totalMinutes += Math.floor(totalSeconds / 60)
    totalSeconds %= 60
    totalHours += Math.floor(totalMinutes / 60)
    totalMinutes %= 60

    return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`
  }

  return (
    <>
      {!!serviceOrderDetail.listaAtendimentos?.length &&
        <>
          <Text style={styles.pageInfo}>Atendimentos Feitos na OS</Text>
          <CardRow>
            <CardDetails minWidth='100%'>
              <DataTable>
                <DataTable.Header>
                  <DataTable.Title>Data</DataTable.Title>
                  <DataTable.Title>Início</DataTable.Title>
                  <DataTable.Title>Fim</DataTable.Title>
                  <DataTable.Title>Responsável</DataTable.Title>
                </DataTable.Header>

                {serviceOrderDetail.listaAtendimentos
                  .filter(atendimento => atendimento.tipoAtendimento !== 'D')
                  .map((atendimento, index) => (
                    <VStack key={index} mb={24}>
                      <DataTable.Row>
                        <DataTable.Cell>Técnico: {atendimento.codTecnico} - {atendimento.tecnico}</DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row>
                        <DataTable.Cell>{dateAdapter.format(atendimento.data, 'DD/MM/yyyy')}</DataTable.Cell>
                        <DataTable.Cell>{dateAdapter.format(atendimento.hrInicio, 'HH:mm:ss')}</DataTable.Cell>
                        <DataTable.Cell>{dateAdapter.format(atendimento.hrFim, 'HH:mm:ss')}</DataTable.Cell>
                        <DataTable.Cell>{atendimento.responsavel}</DataTable.Cell>
                      </DataTable.Row>
                      {atendimento.observacoes &&
                        <DataTable.Row>
                          <DataTable.Cell><Text style={styles.additionalText}>
                            Observações: {atendimento.observacoes}
                          </Text></DataTable.Cell>
                        </DataTable.Row>
                      }
                    </VStack>
                  ))}
              </DataTable>
              <Box style={styles.totalizerTextBox}>
                <Text><Text style={styles.bold}>Total de Horas: </Text>{handleTotalDuration()}</Text>
              </Box>
            </CardDetails>
          </CardRow>
        </>
      }
    </>
  )
}
