import React from 'react'
import { Login } from '@/presentation/pages/Login'
import { makeAuthentication, makeGetUser, makeForgotPassword, makeAuthenticationAdmin } from '@/main/factories/usecases'
import { NavigationPagesProps } from '@/presentation/components'

export const makeLoginFactory = (props: NavigationPagesProps): JSX.Element => {
  return (
    <Login
      {...props}
      authentication={makeAuthentication()}
      authenticationAdmin={makeAuthenticationAdmin()}
      getUser={makeGetUser()}
      forgotPassword={makeForgotPassword()}
    />
  )
}
