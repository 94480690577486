import React, { useCallback } from 'react'
import { View } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ProspectRegistrationParams } from '@/domain/usecases'
import { Text, TextInput, VStack, InputField, Select, Loading } from '@/presentation/components'
import { useFormat, useMediaQueries } from '@/presentation/hooks'
import { styles } from './styles'
import * as State from '@/presentation/pages/Prospect/components/atoms'
import * as GeneralState from '@/presentation/components/Atoms'

type RegisterFormProps = {
  isLoading: boolean
}

export const RegisterForm: React.FC<RegisterFormProps> = (props) => {
  const { isMobile } = useMediaQueries()
  const { formatDocument, formatCEP } = useFormat()
  const [state, setState] = useRecoilState(State.prospectRegistrationState)
  const [selectedInteresetArea, setSelectedInterestArea] = useRecoilState(State.selectedInterestAreaState)
  const [isOpenAreaIntereset, setOpenAreaInterest] = useRecoilState(State.isOpenInterestAreaState)
  const isSendRegistration = useRecoilValue(State.isSendRegistrationState)
  const prospectConfigs = useRecoilValue(GeneralState.prospectConfigsState)
  const interesetArea = useRecoilValue(GeneralState.prospectInterestAreaState)

  const handleChangeInput = useCallback((name: keyof ProspectRegistrationParams, value: string | number): void => {
    setState(currentValues => ({ ...currentValues, [name]: value }))
  }, [])

  const handleChangeCEPInput = useCallback((value: string): void => {
    setState(currentValues => ({ ...currentValues, cep: formatCEP(value) }))
  }, [])

  const handleChangeInputCGC = useCallback((value: string | number): void => {
    const formattedValue = formatDocument(value as string)
    setState(currentValues => ({ ...currentValues, cpf: formattedValue }))
  }, [])

  if (isSendRegistration) {
    return (
      <View
        style={{
          marginHorizontal: 24,
          marginTop: '20vh',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          src={require('../../../../../../assets/register3.svg')}
          alt="Imagem de registro"
          height={isMobile ? 180 : 220}
        />
        <View style={{ maxWidth: 600, width: '100%' }}>
          <Text style={{ fontWeight: '600', fontSize: 18, marginTop: 24, textAlign: 'center' }}>
            Cadastro realizado com sucesso!
          </Text>
          <Text style={{ fontWeight: '400', fontSize: 16, marginTop: 8, textAlign: 'center' }}>
            aguarde o contato da empresa para continuar o acesso!
          </Text>
        </View>
      </View>
    )
  }

  if (props.isLoading) {
    return <Loading text='Registrando cadastro' />
  }

  return (
    <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }} >
      <View style={{ maxWidth: 900, width: '100%' }}>
        <Text variant='subtitle1' style={{ fontWeight: '600', marginVertical: 12 }}>
          Seja bem-vindo
        </Text>

        <Select.Trigger
          label={prospectConfigs?.descricao || 'Selecione a área de interesse*'}
          description={selectedInteresetArea.descricao}
          onOpen={() => setOpenAreaInterest(true)}
        />

        <TextInput
          key='nome'
          label='Qual seu nome completo?*'
          onChangeText={text => handleChangeInput('nome', text)}
          value={state.nome}
          style={styles.input}
        />

        <TextInput
          key='cpf'
          label='Informe seu CPF/CNPJ*'
          onChangeText={text => handleChangeInputCGC(text)}
          value={state.cpf}
          maxLength={18}
          style={styles.input}
        />

        <TextInput
          key='fone'
          label='Qual seu melhor telefone?'
          onChangeText={text => handleChangeInput('fone', text)}
          value={state.fone}
          style={styles.input}
        />

        <TextInput
          key='emailUrl'
          label='Qual seu melhor e-mail?'
          onChangeText={text => handleChangeInput('emailUrl', text)}
          value={state.emailUrl}
          style={styles.input}
        />

        <VStack fill p={1} mt={24}>
          <Text variant='subtitle1' style={{ fontWeight: '600' }}>
            Endereço
          </Text>

          <TextInput
            key='cep'
            label='CEP*'
            onChangeText={text => handleChangeCEPInput(text)}
            value={state.cep}
            maxLength={9}
            style={styles.input}
          />

          <TextInput
            key='endereco'
            label='Endereço'
            onChangeText={text => handleChangeInput('endereco', text)}
            value={state.endereco}
            style={styles.input}
          />
          <InputField>
            <TextInput
              key='numeroEndereco'
              label='Número'
              onChangeText={text => handleChangeInput('numeroEndereco', text)}
              value={state.numeroEndereco}
              style={styles.input}
            />

            <TextInput
              key='complemento'
              label='Complemento'
              onChangeText={text => handleChangeInput('complemento', text)}
              value={state.complemento}
              style={styles.input}
            />
          </InputField>
          <InputField>
            <TextInput
              key='cidade'
              label='Cidade'
              onChangeText={text => handleChangeInput('cidade', text)}
              value={state.cidade}
              style={styles.input}
            />
            <TextInput
              key='estado'
              label='Estado'
              onChangeText={text => handleChangeInput('estado', text)}
              value={state.estado}
              style={styles.input}
              inputStyle={styles.uppercase}
              maxLength={2}
            />
          </InputField>
          <TextInput
              key='bairro'
            label='Bairro'
            onChangeText={text => handleChangeInput('bairro', text)}
            value={state.bairro}
            style={styles.input}
          />
        </VStack>

        <Select.Modal
          isOpen={isOpenAreaIntereset}
          onClose={() => setOpenAreaInterest(false)}
          onSelect={(id, data) => {
            handleChangeInput('codDEInteresse', id)
            setSelectedInterestArea(data)
          }}
          title={prospectConfigs?.descricao || 'Selecione'}
          items={interesetArea?.map(config => ({
            id: config.interesse.codInterno,
            descricao: config.descricao,
            isSelected: selectedInteresetArea.unidade === config.unidade &&
              selectedInteresetArea.interesse.codInterno === config.interesse.codInterno,
            data: config
          }))}
        />
      </View>
    </View>
  )
}
