import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useRecoilValue } from 'recoil'
import { ScrollView } from 'react-native-gesture-handler'
import { ProspectRegistration } from '@/domain/usecases'
import { NavigationRoute } from '@/main/config/router-navigation'
import { Text, NavigationPagesProps, LogoTheme, Loading } from '@/presentation/components'
import { useMediaQueries, useThemeClient } from '@/presentation/hooks'
import { styles } from './styles'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ActionsForm, RegisterForm } from '@/presentation/pages/Prospect/components/'
import * as State from '@/presentation/pages/Prospect/components/atoms'
import * as GeneralState from '@/presentation/components/Atoms'

type ProspectProps = NavigationPagesProps & {
  prospectRegistration: ProspectRegistration
}

export const Prospect: React.FC<ProspectProps> = (props) => {
  const { isMobile } = useMediaQueries()
  const { getThemeClient } = useThemeClient()
  const prospectConfigs = useRecoilValue(GeneralState.prospectConfigsState)
  const isLoadingRegistration = useRecoilValue(State.isLoadingProspectRegistrationState)
  const isLoadingConfigs = useRecoilValue(GeneralState.isLoadingProspectConfigsState)
  const themeClient = getThemeClient()

  useEffect(() => {
    props.navigation.navigate(NavigationRoute.Login)
  }, [prospectConfigs])

  if (isLoadingConfigs || !prospectConfigs) {
    return <Loading text='Buscando configurações de cadastro' />
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={[styles.logoArea, { backgroundColor: themeClient.mainColor }]}>
        <LogoTheme height={40} />
        <Text
          onPress={() => props.navigation.navigate(NavigationRoute.Home)}
          style={[
            styles.title,
            { fontSize: isMobile ? 18 : 20 }
          ]}
          variant='h6'
        >
          Portal do Cliente
        </Text>
      </View>

      <ScrollView style={styles.form}>
        <RegisterForm isLoading={isLoadingRegistration} />
      </ScrollView>

      <ActionsForm
        isLoading={isLoadingRegistration}
        prospectRegistration={props.prospectRegistration}
        navigation={props.navigation}
      />

    </SafeAreaView>
  )
}
