import React, { useCallback, useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { GetUnitConfig, TestEmail, SaveUnitConfig, GetUnitConfigParams, GetUnits } from '@/domain/usecases'
import { Box, currentUnitState, HStack, Loading, Text, UnitsSelector, unitsState } from '@/presentation/components'
import { useSettings, useTheme, useThemeClient, useMediaQueries, useErrorHandler } from '@/presentation/hooks'
import { InvoicesSettings, GeneralSettings, FinancialSettings, ServiceOrderSettings, MenuAccessPermissions, ModalOnSaveOrDiscard, ModalOnDiscardChanges, ModalOnSaveChanges, isLoadingState } from '@/presentation/pages/Settings/components'
import { styles } from './styles'

type SettingsProps = {
  getUnitConfigs: GetUnitConfig
  saveUnitConfig: SaveUnitConfig
  getUnits: GetUnits
  testEmail: TestEmail
}

const Tab = createMaterialTopTabNavigator()

export const Settings: React.FC<SettingsProps> = ({
  getUnitConfigs,
  saveUnitConfig,
  getUnits,
  testEmail
}) => {
  const handleError = useErrorHandler()
  const theme = useTheme()
  const [currentUnit, setCurrentUnit] = useRecoilState(currentUnitState)
  const { getThemeClient } = useThemeClient()
  const { isMobile } = useMediaQueries()
  const { startConfig } = useSettings()
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const setUnits = useSetRecoilState(unitsState)

  const themeClient = getThemeClient()

  useEffect(() => {
    setLoading(true)
    getUnits
      .getAll()
      .then(async units => {
        if (units.length) {
          setUnits(units)
          setCurrentUnit(units.at(0))
        }
      })
      .catch(handleError)
      .finally(() => { setLoading(false) })
  }, [])

  useFocusEffect(
    useCallback(() => {
      if (currentUnit) {
        const getConfigParams: GetUnitConfigParams = {
          codUnidade: currentUnit.codigo
        }

        getUnitConfigs
          .getAll(getConfigParams)
          .then(startConfig)
          .catch(handleError)
          .finally(() => setLoading(false))
      }
    }, [currentUnit])
  )

  const financialSettings = (): JSX.Element => <FinancialSettings />
  const generalSettings = (): JSX.Element => <GeneralSettings testEmail={testEmail} />
  const invoicesSettings = (): JSX.Element => <InvoicesSettings />
  const serviceOrderSettings = (): JSX.Element => <ServiceOrderSettings />
  const menuAccessPermissions = (): JSX.Element => <MenuAccessPermissions />

  return (
    <SafeAreaView style={styles.container}>
      <Box style={styles.content} >
        <HStack justify='center' items='center' style={{
          backgroundColor: `${themeClient.mainColor}CC`
        }}>
          <Text variant="subtitle1" style={{ ...styles.headerText, color: theme.colors.white }} >
            Configurações da Unidade
          </Text>
          <UnitsSelector disabled={isLoading} />
        </HStack>
        <Box style={styles.content}>
          {isLoading
            ? <Loading text='Buscando configurações' />
            : <Tab.Navigator
              screenOptions={{
                tabBarItemStyle: { paddingHorizontal: 40 },
                tabBarLabelStyle: { color: theme.colors.background },
                tabBarIndicatorStyle: { backgroundColor: theme.colors.white },
                tabBarStyle: { backgroundColor: themeClient.mainColor }
              }}
            >
              <Tab.Screen
                options={{
                  tabBarLabel: 'Financeiro',
                  title: 'Financeiro - Portal do Cliente'
                }}
                name='financeiro'
                component={financialSettings}
              />
              <Tab.Screen
                options={{
                  tabBarLabel: 'Gerais',
                  title: 'Configurações Gerais - Portal do Cliente'
                }}
                name='gerais'
                component={generalSettings}
              />
              <Tab.Screen
                options={{
                  tabBarLabel: isMobile ? 'Notas' : 'Notas Fiscais',
                  title: 'Notas Fiscais - Portal do Cliente'
                }}
                name='notas-fiscais'
                component={invoicesSettings}
              />
              <Tab.Screen
                options={{
                  tabBarLabel: isMobile ? 'OSs' : 'Ordens de Serviço',
                  title: 'Ordens de Serviço - Portal do Cliente'
                }}
                name='ordens-servico'
                component={serviceOrderSettings}
              />
              <Tab.Screen
                options={{
                  tabBarLabel: isMobile ? 'Menus' : 'Permissão de Menus',
                  title: 'Permissão de Menu - Portal do Cliente'
                }}
                name='permissao-menus'
                component={menuAccessPermissions}
              />
            </Tab.Navigator>
          }
        </Box>

        <ModalOnSaveOrDiscard saveUnitConfig={saveUnitConfig} />
        <ModalOnSaveChanges saveUnitConfig={saveUnitConfig} />
        <ModalOnDiscardChanges />
      </Box>
    </SafeAreaView>
  )
}
