import React from 'react'
import { StyleSheet } from 'react-native'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Box, Text, Card, Divider, Chip } from '@/presentation/components'
import { selectedOsImageViewState, serviceOrderDetailState } from '@/presentation/pages/ServiceOrderDetail/components'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { TouchableOpacity } from 'react-native-gesture-handler'

export const OsChecklist: React.FC = () => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const serviceOrderDetails = useRecoilValue(serviceOrderDetailState)
  const setSelectedImageView = useSetRecoilState(selectedOsImageViewState)

  const getImageAttachments = (codInternoPergunta: number): string => {
    const image = serviceOrderDetails?.imagensGerais
      .find(image => image.nomeArquivo.endsWith(`PERGUNTA${codInternoPergunta}.png`))

    return image?.caminhoArquivo ?? ''
  }

  return (
    <>
      {!!serviceOrderDetails?.checklist?.perguntas?.length &&
        <>
          <Text style={styles.pageInfo}>Checklist - {serviceOrderDetails?.checklist.titulo.toUpperCase()}</Text>

          <Card
            style={{
              ...styles.cardBase,
              backgroundColor: theme.colors.backgroundCard,
              padding: isMobile ? 12 : 24
            }}>
            {serviceOrderDetails.checklist.perguntas.map((pergunta, index) => {
              const perguntaLabel = `${pergunta.pergunta}`
              const imagemBase64 = getImageAttachments(pergunta.codInterno)

              return (
                <Box key={pergunta.codInterno} style={styles.cardBase}>
                    <Text
                      style={styles.titleQuestion}>
                        PERGUNTA {index + 1}
                    </Text>
                    <Text variant='body2'>
                      {perguntaLabel}
                    </Text>
                    {pergunta.descritiva
                      ? <Text>RESPOSTA: {pergunta.oSCheckListsRespostas.detalhes || 'Não informada'}</Text>
                      : <Chip
                      containerStyle={{ marginBottom: 12 }}
                      text={pergunta.oSCheckListsRespostas.resposta ? 'Sim' : 'Não'}
                      color={pergunta.oSCheckListsRespostas.resposta ? theme.colors.success : theme.colors.error}
                    />
                    }
                    {!!imagemBase64 &&
                      <TouchableOpacity
                        onPress={() => { setSelectedImageView({ description: '', uri: imagemBase64 }) }}
                      >
                        <Box style={styles.imageContainer}>
                          <img style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                          }} src={imagemBase64} alt={`Image anexa ${pergunta.pergunta}`} />
                        </Box>
                      </TouchableOpacity>
                    }
                    <Divider color={theme.colors.greyDarken2} />
                  </Box>
              )})
            }
          </Card>
        </>
      }
    </>
  )
}

export const styles = StyleSheet.create({
  cardBase: {
    display: 'flex',
    borderRadius: 4,
    minWidth: 280,
    maxWidth: 600,
    marginVertical: 12
  },
  pageInfo: {
    fontWeight: '600',
    fontSize: 20,
    marginBottom: 20,
    marginTop: 20
  },
  cardContent: {
    display: 'flex',
    gap: 8
  },
  imageContainer: {
    maxWidth: 140,
    width: '100%',
    height: 140
  },
  titleQuestion: {
    fontSize: 12,
    fontWeight: '700'
  }
})
