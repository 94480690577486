import { ForbiddenError, InvalidCredentialsError, UnexpectedError } from '@/domain/errors'
import { UpdatePassword, UpdatePasswordParams } from '@/domain/usecases'
import { HttpClient, HttpStatusCode } from '@/data/protocols'

export class RemoteUpdatePassword implements UpdatePassword {
  constructor (
    private readonly url: string,
    private readonly httpPostClient: HttpClient<boolean | string>
  ) { }

  async update (params: UpdatePasswordParams): Promise<boolean> {
    const { statusCode, body } = await this.httpPostClient.request({
      method: 'post',
      url: `${this.url}/api/portal/alterar-senha`,
      params
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body as boolean
      case HttpStatusCode.forbidden: throw new ForbiddenError(body as string)
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
