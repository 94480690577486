import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  form: {
    width: '100%',
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 24,
    flex: 1
  },
  input: {
    outlineWidth: 0,
    backgroundColor: 'transparent',
    marginTop: 8
  },
  logoArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 24
  },
  title: {
    color: '#FFF',
    flex: 1
  }
})
