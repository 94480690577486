import { VersionsModel } from '@/domain/models'
import { GetVersions } from '@/domain/usecases'
import { RemoteGetVersions } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeGetVersions = (): GetVersions => {
  const httpClient = makeAxiosHttpClient<VersionsModel>()

  return new RemoteGetVersions(env.API_URL, httpClient)
}
