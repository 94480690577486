import { useCallback } from 'react'

export type ImageBackground = {
  uri: any
}

type ImageProps = {
  getImage: (image: string, defaultImage?: 'logo' | 'login') => Promise<string>
  base64toFile: (b64Data: string, fileName: string, contentType?: string, sliceSize?: number) => File
}

export const useImage = (): ImageProps => {
  const DEFAULT_PATH_IMAGES = __DEV__ ? '/img/' : '/portalservice/img/'

  const getImage = useCallback(async (
    image: string,
    defaultImage: 'logo' | 'login' = 'logo'
  ): Promise<string> => {
    if (!__DEV__) {
      const file = image?.includes('/img/')
        ? image?.split('/img/').pop()?.split('\\').shift() as string
        : image
      const imageFolder = `${DEFAULT_PATH_IMAGES}${file}`

      try {
        return await fetch(imageFolder)
          .then(resp => {
            return resp.status === 200 ? imageFolder : ''
          })
      } catch (error) {
        console.info(`Não foi possível localizar a imagem em ${imageFolder}`, error.message)
      }
    }

    return {
      logo: `${DEFAULT_PATH_IMAGES}logo-inside.svg`,
      login: `${DEFAULT_PATH_IMAGES}background.svg`,
      default: 'LOGO_DEFAULT'
    }[defaultImage]
  }, [])

  const base64toFile = useCallback((
    b64Data: string,
    fileName: string,
    contentType: string = 'image/png',
    sliceSize: number = 512
  ): File => {
    const byteCharacters = window.atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    const file = new File([blob], fileName)
    return file
  }, [])

  return { getImage, base64toFile }
}
