import { atom } from 'recoil'
export * from './ModalUserSelect/atom'

export const isLoadingState = atom({
  key: 'isLoadingLoginState',
  default: false
})

export const isOpenForgotPasswordState = atom({
  key: 'isOpenForgotPasswordState',
  default: false
})

export const loginState = atom({
  key: 'login',
  default: {
    username: '',
    document: '',
    password: '',
    recoveryEmail: ''
  }
})
