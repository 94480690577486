import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { MaterialIcons } from '@expo/vector-icons'
import { NavigationRoute } from '@/main/config/router-navigation'
import { ProspectRegistration, ProspectRegistrationParams } from '@/domain/usecases'
import { Button, HStack, NavigationPagesProps } from '@/presentation/components'
import { useErrorHandler, useFormat, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import * as State from '@/presentation/pages/Prospect/components/atoms'

type RegisterFormProps = NavigationPagesProps & {
  isLoading: boolean
  prospectRegistration: ProspectRegistration
}

export const ActionsForm: React.FC<RegisterFormProps> = (props) => {
  const { getThemeClient } = useThemeClient()
  const theme = useTheme()
  const { validateCPF, validateCNPJ } = useFormat()
  const notify = useNotify()
  const state = useRecoilValue(State.prospectRegistrationState)
  const selectedInteresetArea = useRecoilValue(State.selectedInterestAreaState)
  const [isLoading, setLoading] = useRecoilState(State.isLoadingProspectRegistrationState)
  const [isSendRegistration, setSendRegistration] = useRecoilState(State.isSendRegistrationState)
  const reset = useResetRecoilState(State.prospectRegistrationState)
  const resetInteresetArea = useResetRecoilState(State.selectedInterestAreaState)
  const errorHandler = useErrorHandler()
  const themeClient = getThemeClient()

  const handleRegister = (event: GestureResponderEvent): void => {
    event.preventDefault()
    const errorValidation = documentValidation(state.cpf)
    if (errorValidation) {
      errorHandler(new Error(errorValidation))
      return
    }

    setLoading(true)

    if (!isValidForm()) {
      setLoading(false)
      return
    }

    const params: ProspectRegistrationParams = {
      ...state,
      email: selectedInteresetArea.email,
      codUnidade: selectedInteresetArea.unidade
    }

    props.prospectRegistration
      .register(params)
      .then(() => {
        setSendRegistration(true)
        notify.show('Cadastro realizado')
        resetInteresetArea()
        reset()
      })
      .catch(error => {
        notify.show(error.message, { variant: 'error' })
        setSendRegistration(false)
      })
      .finally(() => { setLoading(false) })
  }

  const isValidForm = (): boolean => {
    if (!selectedInteresetArea.email ||
      !state.nome ||
      !state.cpf ||
      !state.cep ||
      (state.fone && state.fone.length < 9)
    ) {
      return false
    }

    return true
  }

  const documentValidation = (value: string): string => {
    if (!(value.length <= 14 ? validateCPF(value) : validateCNPJ(value))) {
      return 'Por favor, informe um CPF/CNPJ válido'
    }

    if (state.cep.replace(/\D/g, '').length < 8) {
      return 'Por favor, informe um CEP válido'
    }

    return ''
  }

  const goToBackLogin = (): void => {
    props.navigation.navigate(NavigationRoute.Login)
  }

  return (
    <HStack justify='end' pv={18} ph={18}>
      <Button
        color={theme.colors.shadow}
        variant="text"
        leading={props => <MaterialIcons {...props} name="arrow-back" size={24} />}
        title="VOLTAR PARA O LOGIN"
        onPress={goToBackLogin}
        disabled={isLoading}
      />

      {!isSendRegistration &&
        <Button
          variant="text"
          title="Enviar"
          onPress={handleRegister}
          color={theme.colors.success}
          tintColor={themeClient.mainColor}
          disabled={isLoading || !isValidForm()}
        />
        }
    </HStack>
  )
}
