import { SaveTheme } from '@/domain/usecases'
import { RemoteSaveTheme } from '@/data/usecases'
import { makeAxiosHttpClient } from '@/main/factories/http'
import env from '@/main/config/env'

export const makeSaveTheme = (): SaveTheme => {
  const httpClient = makeAxiosHttpClient<string>()

  return new RemoteSaveTheme(env.API_URL, httpClient)
}
