import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { LinkingOptions, NavigationContainer, ParamListBase } from '@react-navigation/native'
import { Header, Loading, notificationsState } from '@/presentation/components'
import { CustomDrawer } from '../CustomDrawer'
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { NavigationRoute, screensClientNavigator, screensAdminNavigator } from '@/main/config/router-navigation'
import { useAuth, useThemeClient } from '@/presentation/hooks'
import {
  makeAcceptDigitalFactory,
  makeCardFactory,
  makeEventsFactory,
  makeFinancialFactory,
  makeHomeFactory,
  makeNFFactory,
  makeRedesSociaisFactory,
  makeServiceOrdersFactory,
  makeSettingsFactory,
  makeThemeFactory,
  makeSacFactory,
  makeTravelAlertFactory,
  makeServiceOrderDetailFactory,
  makeProspectSettingsFactory,
  makeTrackFactory,
  makeContractFactory,
  makeStatementOfDebtsFactory,
  makeEventsSettingsFactory,
  makeLoginFactory,
  makeRecoveryPasswordFactory
} from '@/main/factories/pages'
import { SaveNotificationToken, GetClientNotifications } from '@/domain/usecases'
import { makeProspectFactory } from '@/main/factories/pages/prospect-factory'

export type NavigationPagesProps = {
  navigation: {
    navigate: (route: NavigationRoute) => void
  }
}

const Drawer = createDrawerNavigator()

type NavigationDrawerProps = {
  saveNotificationToken: SaveNotificationToken
  getNotifications: GetClientNotifications
}

export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  saveNotificationToken,
  getNotifications
}) => {
  const { getThemeClient } = useThemeClient()
  const { getClient, isSignedIn, isAdmin } = useAuth()

  const client = getClient()
  const themeClient = getThemeClient()
  const notifications = useRecoilValue(notificationsState)

  const RN_SHOW_MENU_OS = client?.portal?.os ?? false
  const RN_SHOW_MENU_TICKETS = client?.portal?.boleto ?? false
  const RN_SHOW_MENU_NF = client?.portal?.notaFiscal ?? false
  const RN_SHOW_MENU_ACEITE = client?.portal?.aceiteDigital ?? false
  const RN_SHOW_MENU_CARTAO = client?.portal?.cartao ?? false
  const RN_SHOW_MENU_SAC = client?.portal?.sac ?? false
  const RN_SHOW_MENU_MONITORING = client?.portal?.monitoramento ?? false
  const RN_SHOW_MENU_TRAVEL = client?.portal?.viagem ?? false
  const RN_SHOW_MENU_RASTREAMENTO = client?.portal?.rastreamento ?? false
  const RN_SHOW_MENU_CONTRACTS = client?.portal?.contrato ?? false

  const linking: LinkingOptions<ParamListBase> = {
    prefixes: ['/'],
    config: {
      initialRouteName: isAdmin() ? 'Tema' : 'Home' as const,
      screens: {
        ...screensClientNavigator,
        ...screensAdminNavigator
      }
    }
  }

  const makeNotificationTitle = (page: string): string => {
    const pageLabel = notifications.length ? `(${notifications.length}) ${page}` : page
    return `${pageLabel} - Portal do Cliente`
  }

  const makeLogin = (): JSX.Element =>
    <>
      <Drawer.Screen
        options={{
          title: 'Login - Portal do Cliente',
          drawerLabel: 'Login',
          drawerIcon: (props) => (<MaterialCommunityIcons name="login" {...props} />)
        }}
        name={NavigationRoute.Login}
        component={makeLoginFactory}
      />
    </>

  const makeAdminPages = (): JSX.Element =>
    <>
      <Drawer.Screen
        options={{
          title: 'Tema - Portal do Cliente',
          drawerLabel: 'Tema',
          drawerIcon: (props) => (<MaterialCommunityIcons name="brush-variant" {...props} />)
        }}
        name={NavigationRoute.Theme}
        component={makeThemeFactory} />
      <Drawer.Screen
        options={{
          title: 'Configs - Portal do Cliente',
          drawerLabel: 'Configurações',
          drawerIcon: (props) => (<MaterialCommunityIcons name="cog-outline" {...props} />)
        }}
        name={NavigationRoute.Configs}
        component={makeSettingsFactory} />

      <Drawer.Screen
        options={{
          title: 'Prospects - Portal do Cliente',
          drawerLabel: 'Cadastro de Prospect',
          drawerIcon: (props) => (<MaterialCommunityIcons name="account-group" {...props} />)
        }}
        name={NavigationRoute.ProspectSettings}
        component={makeProspectSettingsFactory} />

      <Drawer.Screen
        options={{
          title: 'Eventos - Portal do Cliente',
          drawerLabel: 'Eventos',
          drawerIcon: (props) => (<MaterialCommunityIcons name="alarm-light-outline" {...props} />)
        }}
        name={NavigationRoute.EventsSettings}
        component={makeEventsSettingsFactory}
      />

      <Drawer.Screen
        options={{
          title: 'Redes Sociais - Portal do Cliente',
          drawerLabel: 'Redes Sociais',
          drawerIcon: (props) => (<MaterialCommunityIcons name="earth" {...props} />)
        }}
        name={NavigationRoute.SocialMedia}
        component={makeRedesSociaisFactory}
      />
    </>

  const makeUserPages = (): JSX.Element =>
    <>
      <Drawer.Screen
        options={{
          title: makeNotificationTitle('Home'),
          drawerLabel: 'Home',
          drawerIcon: (props) => (<MaterialCommunityIcons name="home-outline" {...props} />)
        }}
        name={NavigationRoute.Home}
        component={makeHomeFactory}
      />
      {RN_SHOW_MENU_TICKETS &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Financeiro'),
            drawerLabel: 'Financeiro',
            drawerIcon: (props) => (<MaterialIcons name="attach-money" {...props} />)
          }}
          name={NavigationRoute.Financial}
          component={makeFinancialFactory}
        />
      }
      {RN_SHOW_MENU_TICKETS &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Declaração de Débitos'),
            drawerLabel: 'Declaração de Débitos',
            drawerIcon: (props) => (<MaterialIcons name="receipt-long" {...props} />)
          }}
          name={NavigationRoute.StatementOfDebts}
          component={makeStatementOfDebtsFactory}
        />
      }
      {RN_SHOW_MENU_OS &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Ordens de Serviço'),
            drawerLabel: 'Ordens de Serviço',
            drawerIcon: (props) => (<MaterialIcons name="work-outline" {...props} />)
          }}
          name={NavigationRoute.ServiceOrders}
          component={makeServiceOrdersFactory}
        />
      }
      {RN_SHOW_MENU_NF &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Notas Fiscais'),
            drawerLabel: 'Notas Fiscais',
            drawerIcon: (props) => (<MaterialCommunityIcons name="file-document-outline" {...props} />)
          }}
          name={NavigationRoute.Invoices}
          component={makeNFFactory}
        />
      }
      {RN_SHOW_MENU_CARTAO &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Cartão'),
            drawerLabel: 'Cartão',
            drawerIcon: (props) => (<MaterialCommunityIcons name="credit-card-outline" {...props} />)
          }}
          name={NavigationRoute.CreditCard}
          component={makeCardFactory}
        />
      }
      {RN_SHOW_MENU_TRAVEL &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Aviso de Viagem'),
            drawerLabel: 'Aviso de Viagem',
            drawerIcon: (props) => (<MaterialCommunityIcons name="airplane-alert" {...props} />)
          }}
          name={NavigationRoute.TravelAlert}
          component={makeTravelAlertFactory}
        />
      }
      {RN_SHOW_MENU_ACEITE &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Aceites Digitais'),
            drawerLabel: 'Aceites Digitais',
            drawerIcon: (props) => (<MaterialCommunityIcons name="file-document-edit-outline" {...props} />)
          }}
          name={NavigationRoute.DigitalAccepts}
          component={makeAcceptDigitalFactory}
        />
      }
      {RN_SHOW_MENU_MONITORING &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Monitoramento'),
            drawerLabel: 'Monitoramento',
            drawerIcon: (props) => (<MaterialCommunityIcons name="alarm-light-outline" {...props} />)
          }}
          name={NavigationRoute.Monitoring}
          component={makeEventsFactory}
        />
      }
      {RN_SHOW_MENU_SAC &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('SAC'),
            drawerLabel: 'SAC',
            drawerIcon: (props) => (<MaterialIcons name="support-agent" {...props} />)
          }}
          name={NavigationRoute.SAC}
          component={makeSacFactory}
        />
      }
      {RN_SHOW_MENU_RASTREAMENTO &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Rastreamento'),
            drawerLabel: 'Rastreamento',
            drawerIcon: (props) => (<MaterialIcons name="track-changes" {...props} />)
          }}
          name={NavigationRoute.Track}
          component={makeTrackFactory}
        />
      }
      {RN_SHOW_MENU_CONTRACTS &&
        <Drawer.Screen
          options={{
            title: makeNotificationTitle('Meu Contrato'),
            drawerLabel: 'Meu Contrato',
            drawerIcon: (props) => (<MaterialCommunityIcons name="handshake-outline" {...props} />)
          }}
          name={NavigationRoute.Contract}
          component={makeContractFactory}
        />
      }
    </>

  return (
    <NavigationContainer linking={linking} fallback={<Loading />}>
      <Drawer.Navigator
        screenOptions={{
          drawerActiveTintColor: themeClient.mainColor,
          drawerItemStyle: {},
          header: (props: any) => isSignedIn()
            ? <Header
                saveNotificationToken={saveNotificationToken}
                getNotifications={getNotifications}
                {...props}
              />
            : undefined
        }}
        drawerContent={props => <CustomDrawer {...props} />}
        initialRouteName={isAdmin() ? 'Tema' : 'Home'}
      >
        {isSignedIn()
          ? <>{isAdmin() ? makeAdminPages() : makeUserPages()}</>
          : makeLogin()
        }
        <Drawer.Screen
          options={{
            title: 'Cadastro - Portal do Cliente',
            headerShown: false,
            drawerLabel: 'Cadastro de Prospect',
            drawerItemStyle: { display: 'none' }
          }}
          name={NavigationRoute.Prospect}
          component={makeProspectFactory}
        />
        <Drawer.Screen
          options={{
            title: 'Detalhes OS - Portal do Cliente',
            headerShown: false,
            drawerLabel: 'Detalhes OS',
            drawerItemStyle: { display: 'none' }
          }}
          name={NavigationRoute.OsDetail}
          component={makeServiceOrderDetailFactory}
        />
        <Drawer.Screen
          options={{
            title: 'Recuperar Senha - Portal do Cliente',
            headerShown: false,
            drawerLabel: 'Recuperar Senha',
            drawerItemStyle: { display: 'none' }
          }}
          name={NavigationRoute.RecoveryPassword}
          component={makeRecoveryPasswordFactory}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  )
}
