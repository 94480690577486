
export enum NavigationRoute {
  Home = 'home',
  Login = 'login',
  Prospect = 'cadastro',
  Financial = 'financeiro',
  ServiceOrders = 'ordens-servico',
  SAC = 'sac',
  Invoices = 'notas-fiscais',
  DigitalAccepts = 'aceites-digitais',
  CreditCard = 'cartao',
  TravelAlert = 'aviso-viagem',
  PhoneUtils = 'telefone-uteis',
  DocumentsUtils = 'documentos-uteis',
  Monitoring = 'monitoramento',
  Track = 'rastreamento',
  Contract = 'contrato',
  OsDetail = 'os',
  StatementOfDebts = 'declaracao-debitos',

  Theme = 'theme',
  Configs = 'configs',
  SocialMedia = 'redes-socias',
  ProspectSettings = 'prospect-settings',
  EventsSettings = 'events-settings',
  ChangePassword = 'alterar-senha',
  RecoveryPassword = 'recuperar-senha'
}

const handleScreenPath = (screen: string): string => {
  const basePath = __DEV__ ? screen : `portalservice/${screen}`
  return basePath
}

export const screensClientNavigator = {
  [NavigationRoute.Home]: handleScreenPath(NavigationRoute.Home.toString()),
  [NavigationRoute.Prospect]: handleScreenPath(NavigationRoute.Prospect.toString()),
  [NavigationRoute.Financial]: handleScreenPath(NavigationRoute.Financial.toString()),
  [NavigationRoute.Invoices]: handleScreenPath(NavigationRoute.Invoices.toString()),
  [NavigationRoute.SAC]: handleScreenPath(NavigationRoute.SAC.toString()),
  [NavigationRoute.DigitalAccepts]: handleScreenPath(NavigationRoute.DigitalAccepts.toString()),
  [NavigationRoute.ServiceOrders]: handleScreenPath(NavigationRoute.ServiceOrders.toString()),
  [NavigationRoute.CreditCard]: handleScreenPath(NavigationRoute.CreditCard.toString()),
  [NavigationRoute.TravelAlert]: handleScreenPath(NavigationRoute.TravelAlert.toString()),
  [NavigationRoute.PhoneUtils]: handleScreenPath(NavigationRoute.PhoneUtils.toString()),
  [NavigationRoute.DocumentsUtils]: handleScreenPath(NavigationRoute.DocumentsUtils.toString()),
  [NavigationRoute.Monitoring]: handleScreenPath(NavigationRoute.Monitoring.toString()),
  [NavigationRoute.Track]: handleScreenPath(NavigationRoute.Track.toString()),
  [NavigationRoute.OsDetail]: {
    path: `${handleScreenPath(NavigationRoute.OsDetail.toString())}/:param`,
    parse: {
      param: (param: string) => param
    }
  },
  [NavigationRoute.Contract]: handleScreenPath(NavigationRoute.Contract.toString()),
  [NavigationRoute.StatementOfDebts]: handleScreenPath(NavigationRoute.StatementOfDebts.toString()),
  [NavigationRoute.ChangePassword]: handleScreenPath(NavigationRoute.ChangePassword.toString()),
  [NavigationRoute.RecoveryPassword]: {
    path: `${handleScreenPath(NavigationRoute.RecoveryPassword.toString())}/:idRecuperacao`,
    parse: {
      param: (param: string) => param
    }
  }
}

export const screensAdminNavigator = {
  [NavigationRoute.Login]: handleScreenPath(NavigationRoute.Login.toString()),
  [NavigationRoute.Theme]: handleScreenPath(NavigationRoute.Theme.toString()),
  [NavigationRoute.Configs]: handleScreenPath(NavigationRoute.Configs.toString()),
  [NavigationRoute.SocialMedia]: handleScreenPath(NavigationRoute.SocialMedia.toString()),
  [NavigationRoute.ProspectSettings]: handleScreenPath(NavigationRoute.ProspectSettings.toString()),
  [NavigationRoute.EventsSettings]: handleScreenPath(NavigationRoute.EventsSettings.toString())
}
