import { AccessDeniedError, UnexpectedError, UnexpectedResultFormatError } from '@/domain/errors'
import { VersionsModel } from '@/domain/models'
import { GetVersions } from '@/domain/usecases'
import { HttpClient, HttpRequest, HttpStatusCode } from '@/data/protocols/http'

export class RemoteGetVersions implements GetVersions {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<VersionsModel>
  ) {}

  async get (): Promise<VersionsModel> {
    const authParams: HttpRequest = {
      url: `${this.url}/api/portal/versoes`,
      method: 'get'
    }

    const { statusCode, body } = await this.httpClient.request(authParams)

    switch (statusCode) {
      case HttpStatusCode.ok:
        if (!body) {
          throw new UnexpectedResultFormatError()
        }
        return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
