import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useMediaQueries, useTheme } from '@/presentation/hooks'
import { DataTable } from '@/presentation/components'
import { deleteProspectOptionState, isOpenDeleteProspectOptionState } from '@/presentation/pages/ProspectSettings/components'
import * as GeneralState from '@/presentation/components/Atoms'

export const TableAreaOfInterest: React.FC = () => {
  const theme = useTheme()
  const { isMobile } = useMediaQueries()
  const areaInterests = useRecoilValue(GeneralState.prospectInterestAreaState)
  const setDeleteOptionOpen = useSetRecoilState(isOpenDeleteProspectOptionState)
  const setDeleteOption = useSetRecoilState(deleteProspectOptionState)

  const handleDeleteOption = (unit: number, interestArea: string): void => {
    setDeleteOption({
      interesse: interestArea,
      unidade: unit
    })
    setDeleteOptionOpen(true)
  }

  return (
    <DataTable>
      <DataTable.Header>
        {!isMobile && <DataTable.Title>Opção</DataTable.Title>}
        <DataTable.Title>Unidade</DataTable.Title>
        <DataTable.Title>Interesse</DataTable.Title>
        {!isMobile && <DataTable.Title>Email</DataTable.Title>}
      </DataTable.Header>

      {areaInterests.map((area, index) => (
        <DataTable.Row key={index} pointerEvents='auto'>
            {!isMobile && <DataTable.Cell>{area.descricao}</DataTable.Cell>}
          <DataTable.Cell>{area.unidade}</DataTable.Cell>
          <DataTable.Cell>{area.interesse.descreve}</DataTable.Cell>
          {!isMobile && <DataTable.Cell>{area.email}</DataTable.Cell>}
          <DataTable.Cell
            centered
            onPress={() => handleDeleteOption(area.unidade, area.interesse.descreve)}
            style={{ maxWidth: 'fit-content', padding: 4 }}
          >
            <MaterialCommunityIcons name='trash-can' size={24} style={{ color: theme.colors.error }}/>
          </DataTable.Cell>
        </DataTable.Row>
      ))}
    </DataTable>
  )
}
