import React, { useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { SaveProspectConfigs, SaveProspectConfigsParams } from '@/domain/usecases'
import { Box, Button, Text, ModalBase, Loading } from '@/presentation/components'
import { useErrorHandler, useNotify, useProspectSettings, useTheme } from '@/presentation/hooks'
import { isOpenSaveConfigState, isLoadingSaveConfigState } from './atom'
import { styles } from './styles'
import * as GeneralState from '@/presentation/components/Atoms'

type ModalOnSaveChangesProps = {
  saveProspectConfigs: SaveProspectConfigs
}

export const ModalOnSaveChanges: React.FC<ModalOnSaveChangesProps> = ({ saveProspectConfigs }) => {
  const theme = useTheme()
  const notify = useNotify()
  const { startConfig } = useProspectSettings()
  const prospectConfigs = useRecoilValue(GeneralState.prospectConfigsState)
  const areaInterests = useRecoilValue(GeneralState.prospectInterestAreaState)
  const [isLoading, setLoading] = useRecoilState(isLoadingSaveConfigState)
  const [isOpen, setOpen] = useRecoilState(isOpenSaveConfigState)
  const handlerError = useErrorHandler()

  const handleSaveChanges = useCallback((): void => {
    setLoading(true)

    const newProspectConfigs: SaveProspectConfigsParams = {
      ...prospectConfigs,
      areaInteresse: areaInterests
    }

    saveProspectConfigs
      .save(newProspectConfigs)
      .then(() => {
        notify.show('As configurações foram gravadas com sucesso')

        startConfig(newProspectConfigs)
        handleClose()
      })
      .catch(error => {
        handlerError(error)
        handleClose()
      })
      .finally(() => setLoading(false))
  }, [prospectConfigs, areaInterests])

  const handleClose = (): void => setOpen(false)

  return (
    <ModalBase visible={isOpen} onClose={handleClose}>
      {isLoading
        ? <Loading text='Gravando configurações da unidade' />
        : <Box style={styles.container}>
          <Text variant="subtitle1">Deseja realmente gravar as alterações?</Text>

          <Box style={styles.buttonArea}>
            <Button
              color={theme.colors.shadow}
              variant="text"
              title="CANCELAR"
              onPress={handleClose}
            />
            <Button
              title="GRAVAR"
              color={theme.colors.success}
              tintColor={theme.colors.white}
              onPress={handleSaveChanges}
              disabled={isLoading}
            />
          </Box>
        </Box>
      }
    </ModalBase>
  )
}
