import { atom } from 'recoil'
import { ProspectConfigsModel } from '@/domain/models'

export const isLoadingState = atom({
  key: 'prospectSettingsLoadingState',
  default: true
})

export const initialProspectConfigsState = atom({
  key: 'initialProspectConfigsState',
  default: {} as ProspectConfigsModel
})
