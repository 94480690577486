import React, { useState, useCallback } from 'react'
import { SafeAreaView } from 'react-native'
import { useRecoilState } from 'recoil'
import { useFocusEffect } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NotFoundError } from '@/domain/errors'
import { GetTheme, GetUnits, GetVersions, SaveTheme, UploadFile } from '@/domain/usecases'
import { useErrorHandler, useImage, useNotify, useTheme, useThemeClient } from '@/presentation/hooks'
import { Box, HStack, VStack, Text, Loading, Chip, unitsState } from '@/presentation/components'
import { ActionsButtons, ColorPicker, LoginBackgroundSelector, LogoSelector, ModalOnDiscardChanges, ModalOnSaveChanges, errorThemeState, isLoadingState } from '@/presentation/pages/Theme/components'
import { styles } from './styles'
import { version as portalVersion } from '../../../../package.json'

export type ThemeProps = {
  getTheme: GetTheme
  getUnits: GetUnits
  saveTheme: SaveTheme
  uploadFile: UploadFile
  getVersions: GetVersions
}

export const Theme: React.FC<ThemeProps> = (props) => {
  const theme = useTheme()
  const notify = useNotify()
  const { getImage } = useImage()
  const { hasChanged, setThemeClient, getThemeClient } = useThemeClient()
  const [logoEmpresa, setLogoEmpresa] = useState('')
  const [backgroundEmpresa, setBackgroundEmpresa] = useState('')
  const [isLoading, setLoading] = useRecoilState(isLoadingState)
  const [error, setError] = useRecoilState(errorThemeState)
  const [units, setUnits] = useRecoilState(unitsState)
  const handleError = useErrorHandler(error => {
    if (error instanceof NotFoundError) {
      setError('O endereço do IntegraService da unidade não está respondendo, verifique se foi configurado corretamente e tente novamente')
    }
  })

  const themeClient = getThemeClient()
  const hasExternalServerUrl = !!units.find(unit => themeClient.codigoUnidade === unit.codigo)?.enderecoExterno

  const validateOutdatedApps = (): void => {
    props.getVersions
      .get()
      .then(versions => {
        let needUpdate = false

        const currentAppVersionNumber = +portalVersion.replaceAll('.', '')
        const lastAppVersionNumber = +versions.versaoAtualPortal.replaceAll('.', '')
        if ((lastAppVersionNumber && currentAppVersionNumber) && (lastAppVersionNumber > currentAppVersionNumber)) {
          needUpdate = true
        }

        const currentIntegraVersionNumber = +versions.versaoLocalIntegra.replaceAll('.', '')
        const lastIntegraVersionNumber = +versions.versaoAtualIntegra.replaceAll('.', '')
        if ((lastIntegraVersionNumber && currentIntegraVersionNumber) && lastIntegraVersionNumber > currentIntegraVersionNumber) {
          needUpdate = true
        }

        needUpdate && notify.warning('Versão desatualizada. Por favor, entre em contato com o suporte e atualize para a versão mais recente.')
      })
      .catch(console.error)
  }

  useFocusEffect(
    useCallback(() => {
      setError('')

      setLoading(true)

      props.getUnits
        .getAll()
        .then(async units => {
          if (units.length) {
            setUnits(units)
          }
        })
        .catch(error => {
          handleError(error)
          setLoading(false)
        })

      handleGetTheme()
    }, [])
  )

  const handleGetTheme = useCallback((): void => {
    setLoading(true)
    props.getTheme
      .get()
      .then(theme => {
        setThemeClient(theme)

        void getImage(theme.imageLogo, 'logo')
          .then(logo => { setLogoEmpresa(logo) })
          .catch(console.error)
        void getImage(theme.bgLogin, 'login')
          .then(background => { setBackgroundEmpresa(background) })
          .catch(console.error)

        validateOutdatedApps()
      })
      .catch(handleError)
      .finally(() => { setLoading(false) })
  }, [themeClient.imageLogo, themeClient.bgLogin])

  if (isLoading) {
    return <Loading text='Buscando Tema da Unidade' />
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scroll}>
        <HStack justify='center' items='center' style={{
          backgroundColor: `${themeClient.mainColor}CC`
        }}>
          <MaterialCommunityIcons size={24} color={theme.colors.white} name='brush-variant' />
          <Text variant="subtitle1" style={{ ...styles.headerText, color: theme.colors.white }} >
            Tema
          </Text>

          {(!hasExternalServerUrl && !!units.length && !isLoading) &&
             <Chip color={theme.colors.error} text='Requer configuração de Endereço Externo da Unidade' />
          }
        </HStack>
        {error
          ? <Box style={{ ...styles.content, alignItems: 'center' }}>
              <Chip color={theme.colors.error} text={error} />
            </Box>
          : <Box style={styles.content}>
              {['css', 'padrao'].includes(themeClient?.fonte) &&
                <HStack pv={12} wrap justify='center' spacing={24}>
                  <Chip color={theme.colors.error} text='Tema Padrão' />
                  <Text style={{ marginLeft: 12, fontWeight: '600', fontSize: 12 }}>Requer primeira gravação</Text>
                </HStack>
              }
              <HStack wrap spacing={24}>
                <ColorPicker label='Cor principal' name='mainColor' />
                <ColorPicker label='Cor dos botões de confirmação' name='buttonSave' />
              </HStack>
              <HStack wrap spacing={24}>
                <ColorPicker label='Cor do título da tela de login' name='loginColor' />
                <ColorPicker label='Cor dos ícones do menu superior' name='menuColor' />
              </HStack>
              <VStack m={16}>
                <LogoSelector fileName={logoEmpresa} />
                <LoginBackgroundSelector fileName={backgroundEmpresa} />
              </VStack>
            </Box>
        }
      </ScrollView>
      {(hasChanged() || ['css', 'padrao'].includes(themeClient?.fonte)) &&
        <ActionsButtons firstRecording={['css', 'padrao'].includes(themeClient?.fonte)} />
      }
      <ModalOnSaveChanges uploadFile={props.uploadFile} saveTheme={props.saveTheme} />
      <ModalOnDiscardChanges />
    </SafeAreaView>
  )
}
