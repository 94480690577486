import React, { useCallback, useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { GetClientByCode, MetricsLogin, SaveNotificationToken } from '@/domain/usecases'
import { KEY_ACCESS_PASSWORD, useAuth, useErrorHandler } from '@/presentation/hooks'
import { isLoadingState, Menu } from '@/presentation/pages/Home/components'
import { authenticationState, isOpenUpdatePasswordState, localStorageState, PhoneModal } from '@/presentation/components'
import { DateAdapter } from '@/data/protocols'
import { version } from '../../../../package.json'

type HomeProps = {
  getClientByCode: GetClientByCode
  navigation: any
  metricsLogin: MetricsLogin
  dateAdapter: DateAdapter
}

export const Home: React.FC<HomeProps> = ({
  getClientByCode,
  navigation,
  metricsLogin,
  dateAdapter
}) => {
  const { setClient, getCurrentClient } = useAuth()
  const setLoading = useSetRecoilState(isLoadingState)
  const [autentication, setAuthentication] = useRecoilState(authenticationState)
  const setOpenUpdatePassword = useSetRecoilState(isOpenUpdatePasswordState)
  const localStorageAdapter = useRecoilValue(localStorageState)
  const handleError = useErrorHandler(console.error)

  const currentClient = getCurrentClient()

  useEffect(() => {
    setOpenUpdatePassword(!!localStorageAdapter.get(KEY_ACCESS_PASSWORD))

    return () => {
      setOpenUpdatePassword(false)
    }
  }, [])

  useFocusEffect(
    useCallback(() => {
      const tokenCliente = localStorageAdapter.get(SaveNotificationToken.RN.LOCAL_STORAGE_TOKEN)

      setLoading(true)

      getClientByCode
        .getByCode({
          codigoCliente: !isNaN(+currentClient) ? +currentClient : 9303,
          tokenDispositivo: tokenCliente
        })
        .then(client => {
          setClient(client)
          if (autentication.registerLogin) {
            void metricsLogin
              .register({
                dataOcorrencia: dateAdapter.format(dateAdapter.today(), 'YYYY-MM-DDTHH:mm:ssZ'),
                email: client.email,
                unidade: client.unidade,
                usuario: client.codCliente.toString(),
                versao: version
              })
              .then(() => setAuthentication(currentState => ({ ...currentState, registerLogin: false })))
              .catch(console.error)
          }
        })
        .catch(handleError)
        .finally(() => setLoading(false))
    }, [currentClient])
  )

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Menu navigation={navigation} />
      <PhoneModal/>
    </SafeAreaView>
  )
}
