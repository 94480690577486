type FormatProps = {
  formatCurrency: (amount: number) => string
  removeNewLines: (text: string) => string
  formatDocument: (document: string) => string
  formatCEP: (cep: string) => string
  validateCPF: (cpf: string) => boolean
  validateCNPJ: (cpf: string) => boolean
}

export const useFormat = (): FormatProps => {
  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat(
      'pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(amount)
  }

  const removeNewLines = (text: string): string => {
    return text.replace(/(\r\n|\n|\r)/gm, ' ')
  }

  const validateCPF = (cpf: string): boolean => {
    const cpfNumeros = cpf.replace(/\D/g, '')

    if (cpfNumeros.length !== 11) {
      return false
    }

    if (/^(\d)\1{10}$/.test(cpfNumeros)) {
      return false
    }

    let soma = 0
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpfNumeros[i]) * (10 - i)
    }

    let resto = soma % 11
    const digito1 = resto < 2 ? 0 : 11 - resto

    soma = 0
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpfNumeros[i]) * (11 - i)
    }

    resto = soma % 11
    const digito2 = resto < 2 ? 0 : 11 - resto

    if (parseInt(cpfNumeros[9]) !== digito1 || parseInt(cpfNumeros[10]) !== digito2) {
      return false
    }

    return true
  }

  const validateCNPJ = (cnpj: string): boolean => {
    const cnpjNumeros = cnpj.replace(/\D/g, '')

    if (cnpjNumeros.length !== 14) {
      return false
    }

    if (/^(\d)\1{13}$/.test(cnpjNumeros)) {
      return false
    }

    let soma = 0
    const peso1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

    for (let i = 0; i < 12; i++) {
      soma += parseInt(cnpjNumeros[i]) * peso1[i]
    }

    let resto = soma % 11
    const digito1 = resto < 2 ? 0 : 11 - resto

    soma = 0
    const peso2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

    for (let i = 0; i < 13; i++) {
      soma += parseInt(cnpjNumeros[i]) * peso2[i]
    }

    resto = soma % 11
    const digito2 = resto < 2 ? 0 : 11 - resto

    if (parseInt(cnpjNumeros[12]) !== digito1 || parseInt(cnpjNumeros[13]) !== digito2) {
      return false
    }

    return true
  }

  const formatDocument = (document: string): string => {
    const numericValue = document.replace(/\D/g, '')

    if (numericValue.length <= 11) {
      return numericValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    } else if (numericValue.length <= 14) {
      return numericValue
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})$/, '$1-$2')
    }

    return numericValue
  }

  const formatCEP = (cep: string): string => {
    const numericValue = cep.replace(/\D/g, '')
    return numericValue.replace(/^(\d{5})(\d{1,3})?$/, '$1-$2').substring(0, 9)
  }

  return {
    formatCurrency,
    formatDocument,
    formatCEP,
    removeNewLines,
    validateCPF,
    validateCNPJ
  }
}
