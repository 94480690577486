import { useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ThemeModel } from '@/domain/models'
import { localStorageState } from '@/presentation/components'
import { initialThemeState, selectedBackgroundState, selectedLogoState, themeState } from '@/presentation/pages/Theme/components'

type ThemeClient = {
  getThemeClient: () => ThemeModel
  setThemeClient: (theme: ThemeModel) => void
  hasChanged: () => boolean
}

export const KEY_THEME_STORAGE = 'portalTheme'

export const useThemeClient = (): ThemeClient => {
  const [themeClient, setTheme] = useRecoilState(themeState)
  const [initialTheme, setInitialTheme] = useRecoilState(initialThemeState)
  const selectedLogo = useRecoilValue(selectedLogoState)
  const selectedBackground = useRecoilValue(selectedBackgroundState)
  const localStorageAdapter = useRecoilValue(localStorageState)

  const setThemeClient = useCallback((theme: ThemeModel): void => {
    setInitialTheme(theme)
    setTheme(currentState => ({ ...currentState, theme }))

    const themeColorClient = document?.querySelector('meta[name="theme-color"]')
    if (themeColorClient) {
      themeColorClient.setAttribute('content', theme.mainColor)
    }

    localStorageAdapter.set(KEY_THEME_STORAGE, theme)
  }, [])

  const getThemeClient = useCallback((): ThemeModel => {
    return themeClient.theme
  }, [themeClient.theme])

  const hasChanged = useCallback((): boolean => {
    return JSON.stringify(themeClient.theme) !== JSON.stringify(initialTheme) || !!selectedLogo || !!selectedBackground
  }, [themeClient.theme, initialTheme, selectedLogo, selectedBackground])

  return {
    getThemeClient,
    setThemeClient,
    hasChanged
  }
}
