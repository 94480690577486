import { FileModel } from '@/domain/models'
import { UploadFile, UploadFileParams } from '@/domain/usecases'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/data/protocols/http'

export class RemoteUploadFile implements UploadFile {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<FileModel>
  ) { }

  async upload (params: UploadFileParams): Promise<FileModel> {
    const formData = new FormData()
    formData.append('file', params.file)
    formData.append('name', params.scope)

    const { statusCode, body } = await this.httpClient.request({
      method: 'post',
      url: `${this.url}/api/portal/image`,
      body: formData,
      headers: {
        ContentType: 'multipart/form-data'
      }
    })

    switch (statusCode) {
      case HttpStatusCode.ok: return body
      case HttpStatusCode.unauthorized: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
